@import 'styles/variables'

.check
  display: inline-flex
  justify-content: center
  align-items: center
  width: 2rem
  height: 2rem
  box-sizing: border-box
  cursor: pointer
  border: .1rem solid $gray-border
  background-color: adjust-color($white, $alpha: -.2)
  transition: background .15s ease, border .15s ease, box-shadow .15s ease

  &:hover
    background-color: $white
    box-shadow: 0 0 .5rem $gray-shadow

  .icon
    max-width: none
    height: 1.25rem
    transition: opacity .15s ease
    opacity: 0

  &.checked
    background-color: $secondary-orange-light
    border-color: $secondary-orange-light

    &:hover
      background-color: lighten($secondary-orange-light, 20%)
      border-color: lighten($secondary-orange-light, 20%)

    .icon
      opacity: 1

  &.withicon
    .icon
      opacity: 1

  &.circular
    border-radius: 50%

  &.large
    width: 3rem
    height: 3rem

    .icon
      max-width: none
      height: 1.35rem
