@import 'styles/variables'

.pac-container.hdpi
  &.pac-logo:after
    display: none

  .pac-item
    position: relative
    font-size: 1.4rem
    color: $gray-text
    padding: 1.5rem 2.5rem
    box-sizing: border-box
    line-height: 100%
    border-top: 0

    &:before
      position: absolute
      top: 0
      left: 2.5rem
      content: ''
      height: .1rem
      width: calc(100% - 5rem)
      background-color: $gray-border

    &:first-child
      &:before
        display: none

    .pac-icon.pac-icon-marker
      display: none
