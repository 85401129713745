@import 'styles/variables'

input[type=range]
  -webkit-appearance: none
  width: 100%
  margin: .8rem 0

  &:focus
    outline: none

  &::-webkit-slider-runnable-track
    width: 100%
    height: .2rem
    cursor: pointer
    background: $black
    border-radius: 0
    border: none

  &::-webkit-slider-thumb
    // border: .1rem solid $secondary-orange-light
    border: .1rem solid $gray-border
    height: 2rem
    width: 2rem
    border-radius: 50%
    // background: $secondary-orange-light
    background: $white
    cursor: pointer
    -webkit-appearance: none
    margin-top: -.8rem
    box-shadow: $secondary-card-shadow
    transition: background .3s ease, border .3s ease

    &:hover
      border-color: $gray-secondary

  &:focus::-webkit-slider-runnable-track
    background: $black

  &::-moz-range-track
    width: 100%
    height: .2rem
    cursor: pointer
    background: $black
    border-radius: 0
    border: none

  &::-moz-range-thumb
    // border: .1rem solid $secondary-orange-light
    border: .1rem solid $gray-border
    height: 2rem
    width: 2rem
    border-radius: 50%
    // background: $secondary-orange-light
    background: $white
    cursor: pointer
    box-shadow: $secondary-card-shadow
    transition: background .3s ease, border .3s ease

    &:hover
      border-color: $gray-secondary

  &::-ms-track
    width: 100%
    height: .2rem
    cursor: pointer
    background: transparent
    border-color: transparent
    color: transparent

  &::-ms-fill-lower, &::-ms-fill-upper
    background: $black
    border: none
    border-radius: 0

  &::-ms-thumb
    // border: .1rem solid $secondary-orange-light
    border: .1rem solid $gray-border
    height: 2rem
    width: 2rem
    border-radius: 50%
    // background: $secondary-orange-light
    background: $white
    cursor: pointer
    box-shadow: $secondary-card-shadow
    transition: background .3s ease, border .3s ease

    &:hover
      border-color: $gray-secondary

  &:focus
    &::-ms-fill-lower
      background: $black

    &::-ms-fill-upper
      background: $black
