@import 'styles/variables'

.tooltip-icon,
.icon.tooltip-icon
  height: $h4-size
  max-width: none
  vertical-align: bottom
  cursor: help

  &.space-left
    margin-left: .5rem

  &.space-right
    margin-right: .5rem

  &.space-larger-left
    margin-left: .5rem

  &.space-larger-right
    margin-right: .5rem

  &.va-middle
    vertical-align: middle

.__react_component_tooltip
  max-width: calc(100vw - 4rem)
