@import 'styles/variables'

html
  font-size: 62.5%

body
  margin: 0
  padding: 0
  font-family: $font-family
  font-size: $font-size
  color: $text-color
  background-color: $white
  overflow-x: hidden
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

h1, h2, h3, h4, h5, h6
  color: $heading-color

  &.decorated
    position: relative

    &:before
      content: ''
      display: inline-block
      position: absolute
      left: -1.5rem
      top: 50%
      width: .5rem
      height: 80%
      background-color: $secondary-orange-light
      transform: translateY(-50%)
      transition: background-color .15s ease

    &.orange
      &:before
        background-color: $secondary-orange-light

    &.blue
      &:before
        background-color: $secondary-blue-light

    &.green
      &:before
        background-color: $secondary-green-light

h1
  font-size: $h1-size

h2
  font-size: $h2-size

h3
  font-size: $h3-size

h4
  font-size: $h4-size

button:focus
  outline: none

hr
  width: 100%
  display: block
  height: .2rem
  background: $gray-border
  border: none

.description
  color: $description-color

.gray-text
  color: $gray-text

.red-text
  color: $red

.light
  font-weight: 300

.medium
  font-weight: 500

.semibold
  font-weight: 600

.card-primary
  background-color: $white
  box-shadow: $primary-card-shadow

.card-secondary
  background-color: $white
  box-shadow: $secondary-card-shadow

.nowrap
  white-space: nowrap

// Import all external libs
@import './external'

// Import all miscellaneous global styles
@import './misc/'
