@import 'styles/variables'

input,
textarea,
select
  font-family: $font-family

  &:focus
    outline: none

  &::placeholder
    color: $gray-text
    font-family: $font-family

  &::-webkit-input-placeholder
    color: $gray-text
    font-family: $font-family

  &::-moz-placeholder
    color: $gray-text
    font-family: $font-family

  &:-ms-input-placeholder
    color: $gray-text
    font-family: $font-family

  &:-moz-placeholder
    color: $gray-text
    font-family: $font-family

input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="date"],
input[type="time"],
input[type="number"],
textarea,
select
  color: $black
  height: 4rem
  box-sizing: border-box
  padding: .8rem 1.5rem
  border: 1px solid $gray-border
  background-color: $white
  font-size: 1.4rem
  transition: background .3s ease, border .3s ease, box-shadow .3s ease

  &.withicon
    background-repeat: no-repeat
    background-size: auto 1.5rem

    &.left
      padding-left: 3rem
      background-position: 1rem center

    &.right
      padding-right: 3rem
      background-position: right 1rem center

    $icons: marker, date, clock, stopwatch, pack, envelope, profile, phone
    @each $icon in $icons
      &.#{$icon}
        background-image: url('/images/icons/#{$icon}.svg')

  &:hover
    border-color: $gray-secondary

  &:focus
    border-color: $black
    box-shadow: $secondary-card-shadow

  &:disabled
    cursor: not-allowed
    background-color: $gray-disabled

    &:hover
      border-color: $gray-border

textarea
  font-family: $font-family !important
  height: auto
  min-height: 10rem
  max-width: 100%
  min-width: 100%

  &.reference
    height: 4rem
    min-height: 4rem

    @media screen and (max-width: 600px)
      height: auto

select
  font-family: $font-family !important
  display: block
  max-width: 100%
  margin: 0
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  border-radius: 0
  background-image: url('/images/icons/caret_down.svg')
  background-repeat: no-repeat
  background-position: right 1.9rem top 50%
  background-size: 1.2rem auto
  padding-right: 4.6rem

  &:hover
    cursor: pointer

  &::-ms-expand
    display: none

  option
    font-weight: normal

label[for]
  display: inline-block
  font-size: 1.2rem
  color: $gray-text
  margin-bottom: 1rem

  &.flex
    display: flex
    align-items: flex-end

    .end
      margin-left: auto

      &.smaller
        color: $text-color
        font-size: .8em

  .red
    color: $red

input.error-input,
textarea.error-input
  border-color: $red

  &:hover,
  &:focus
    border-color: $red

span.error-message
  display: inline-block
  font-size: 1.2rem
  font-weight: bold
  color: $red
  margin-top: .5rem

label.checkbox
  display: inline-flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  cursor: pointer

  &.disabled
    cursor: not-allowed

  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

    &:checked ~ .checkmark
      background-color: $secondary-orange-light
      border-color: $secondary-orange-light

      &:after
        display: block

    &:disabled ~ .checkmark
      background-color: $gray-disabled
      cursor: not-allowed

  .checkmark
    position: relative
    width: 2rem
    height: 2rem
    box-sizing: border-box
    border: .1rem solid $gray-border
    background-color: adjust-color($white, $alpha: -.5)
    transition: background .3s ease, border .3s ease
    border-radius: 50%

    &:after
      content: ""
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      width: 100%
      height: 100%
      background-image: url('/images/icons/check.svg')
      background-position: center center
      background-repeat: no-repeat
      background-size: 70% auto
      display: none

  &:hover
    input ~ .checkmark
      border-color: $gray-secondary

    input:disabled ~ .checkmark
      border-color: $gray-border

  &.square
    .checkmark
      border-radius: 0

  .label-before,
  .label-after
    display: inline-block

  .label-before
    margin-right: 1rem

  .label-after
    margin-left: 1rem

  .tooltip-icon
    margin-left: 1rem
    margin-bottom: -.2rem

@import './input-range'
