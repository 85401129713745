.iti
  input
    width: 100%

  &.iti--allow-dropdown input,
  &.iti--separate-dial-code input
    padding-left: 5.5rem

  .iti__selected-flag
    padding: 0 .75rem 0 1.5rem
