@import 'styles/variables'

.button
  box-sizing: border-box
  font-size: 1.4rem
  border: 1px solid $black
  height: 4rem
  padding: .8rem 1.9rem
  cursor: pointer
  transition: background-color .15s ease, box-shadow .15s ease
  position: relative
  overflow: hidden

  @media screen and (max-width: 800px)
    height: auto
    min-height: 4rem
    white-space: normal

  &:disabled,
  &[aria-disabled="true"]
    cursor: not-allowed

  &.textandicon
    padding-left: 1.4rem
    padding-right: 1.4rem

    .textandicon-wrap
      display: flex
      align-items: center

      .icon,
      span
        display: inline-block
        margin-left: .5rem
        margin-right: .5rem

      .icon
        max-width: none
        vertical-align: unset

  & > .icon:only-child,
  & > .icon.absolute
    font-size: 1.6rem
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &.primary
    background-color: $black
    color: $white

    .icon
      fill: $white

    &:hover
      box-shadow: 0 0 .5rem 0 #bbbbbb

    &:disabled,
    &[aria-disabled="true"]
      background-color: $gray-disabled
      border-color: $gray-disabled

    &.red
      background-color: $red
      border-color: $red
      color: $white

    &.orange
      background-color: $secondary-orange-dark
      border-color: $secondary-orange-dark
      color: $white

    &.blue
      background-color: $secondary-blue-dark
      border-color: $secondary-blue-dark
      color: $white

    &.green
      background-color: $secondary-green-dark
      border-color: $secondary-green-dark
      color: $white

  &.secondary
    background-color: $white
    color: $black

    &:hover
      background-color: $gray-background

    &:disabled,
    &[aria-disabled="true"]
      color: $gray-disabled
      border-color: $gray-disabled

      .icon
        fill: $gray-disabled

    &.noborder
      border: 1px solid transparent

      &:hover
        border-color: transparent
        background-color: transparent

    &.nobackground
      background-color: transparent

      &:hover
        background-color: transparent

    &.red-border
      border-color: $red

      &:disabled,
      &[aria-disabled="true"]
        border-color: lighten($red, 20%)

    &.red
      color: $red
      border-color: $red

      .icon
        fill: $red

      &:disabled,
      &[aria-disabled="true"]
        color: lighten($red, 20%)
        border-color: lighten($red, 20%)

        .icon
          fill: lighten($red, 20%)

    &.orange
      color: $secondary-orange-dark
      border-color: $secondary-orange-dark

      .icon
        fill: $secondary-orange-dark

      &:disabled,
      &[aria-disabled="true"]
        color: lighten($secondary-orange-dark, 20%)
        border-color: lighten($secondary-orange-dark, 20%)

        .icon
          fill: lighten($secondary-orange-dark, 20%)

    &.blue
      color: $secondary-blue-dark
      border-color: $secondary-blue-dark

      .icon
        fill: $secondary-blue-dark

      &:disabled,
      &[aria-disabled="true"]
        color: lighten($secondary-blue-dark, 20%)
        border-color: lighten($secondary-blue-dark, 20%)

        .icon
          fill: lighten($secondary-blue-dark, 20%)

    &.green
      color: $secondary-green-dark
      border-color: $secondary-green-dark

      .icon
        fill: $secondary-green-dark

      &:disabled,
      &[aria-disabled="true"]
        color: lighten($secondary-green-dark, 20%)
        border-color: lighten($secondary-green-dark, 20%)

        .icon
          fill: lighten($secondary-green-dark, 20%)

  & + label.underlabel
    display: inline-block
    margin-top: .5rem
    font-size: 1rem
    text-align: center
    white-space: normal
